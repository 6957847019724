<template>
  <FKawasanTable
      :kawasan = "true"
  ></FKawasanTable>
</template>

<script>
import FKawasanTable from "../../components/admin_potensi/potensi/FPotensiTable";
export default {
  name: "KawasanVie",
  components:{
    FKawasanTable
  }
}
</script>

<style scoped>

</style>